.carousel .thumbs-wrapper,
.carousel-status {
  display: none;
}
.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}
.parent-loader {
  background-color: #231e3d;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}
.font-link {
  font-family: "Manrope", sans-serif;
}
.c-btn span:first-child {
  transform: translateX(-101%);
  transition: transform 0.3s ease-in;
}
.c-btn:hover span {
  transform: translateX(0);
}
.socials .footer-div span:last-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
}
.socials span {
  transition: transform 0.2s ease-out;
}

.socials .footer-div:hover span:first-child {
  transform: translateY(100%);
}

.socials .footer-div:hover span:last-child {
  transform: none;
}
/* .girl-illustration {
  transform-origin: center;
  transform-style: preserve-3d;
  transform: scale(1);
  transition: transform 225ms ease-out;
} */
/* .girl-illustration:hover {
  transform: scale(1) rotateX(30deg) rotateY(0deg);
  transition: transform 380ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
} */
.scrollTop {
  position: fixed;
  width: auto;
  bottom: 20px;
  right: 30px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@media screen and (max-width: 768px) {
  .contact-btn {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  :placeholder-shown {
    font-size: 12px;
  }
}
@media screen and (min-width: 424px) and (max-width: 500px) {
  .submit-btn {
    top: 7%;
  }
}
